<template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Payment Volume</label>
                            <v-text-field
                            v-model="range"
                            :rules="rangeRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn  color="blue darken-1" @click="cancel" dark>
                                Cancel
                            </v-btn>

                            <v-btn  :disabled="!valid || loading" :color="`${zamuYellow3}`"  class="mr-4 zamu-sub-heading "  @click="save" dark >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'EditPaymentVolume',
    mixins: [coreMixin, colorMixin],
     data () {
       return {
            volume: null,
            range: '',
            notification: '',
            actionClass: '',
            formTitle: 'Edit Payment Volume',
             valid: false,
             loading: false,
             volumeID: null,
       };
     },


    async mounted() {
       const id = this.$route.params.id;
       this.volumeID = id;
        this.volume = await this.retrieveVolume(id);
        this.range = this.volume.range;

    },
    methods: {
        async retrieveVolume(id) {
              const endpoint = `payment-volume/${id}`;

              const fullPayload = {
                  app: this.apiUrl,
                  endpoint,
              };

              try {
                  const response = await this.axiosGetRequest(fullPayload);
                  const volume = response.status === 200 ? response.data : null;
                  return volume;
              } catch (error) {
                return null;
              }
          },
          async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                          range: this.range,
                          
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `payment-volume/update/${this.volumeID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'Payment volume updated successful' : 'Payment Volume failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'Payment Volume failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/payment-volumes');

          },
        }

}
</script>

<style>

</style>